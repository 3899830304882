import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

// import { Button } from "../../components/buttons/buttons"
import { Card } from "../../components/card/card"
import { CardSliderDuo } from "../../components/card-slider-duo/card-slider-duo"
import { HeadingBasic, HeadingColumn } from "../../components/heading/heading"
import { Section, Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Cover } from "../../components/cover/cover"
import { Link } from "../../components/utils/utils"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"

// import SinarmaslandVIdeo from "../../assets/video/sinarmasland.mp4"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Career = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgVideoCover: file(
        relativePath: { eq: "home/bg_home_cover-hero-video.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "career") {
          title
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
            ...CoreHeadingBlock
            ...AcfLocationCardsBlock
            ...CoreEmbedYoutube
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
              ...CoreHeadingBlock
              ...AcfLocationCardsBlock
              ...CoreEmbedYoutube
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          className="cover-md cover-detail"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/paragraph":
            if (i === 1) {
              return (
                <Section key={`paragraph-${i}`} className="pt-0-sm bg-light">
                  <Container className="mw-lg">
                    <HeadingBasic text={[item.attributes.content]} />
                  </Container>
                </Section>
              )
            } else {
              return (
                <Section key={`paragraph-${i}`} className="pt-0-sm bg-light">
                  <Container className="mw-lg">
                    <HeadingBasic text={[item.attributes.content]} />
                  </Container>
                </Section>
              )
            }
          case "core/group":
            if (i === 3) {
              let testimonial = []
              item.innerBlocks.map(coverBlock => {
                const button = coverBlock.innerBlocks.find(
                  x => x.name === "core/buttons"
                )
                const text = item.innerBlocks[0].innerBlocks.reduce(
                  (result, currentValue) => {
                    if (currentValue.name === "core/paragraph") {
                      result.push(currentValue.attributes.content)
                    }
                    return result
                  },
                  []
                )
                let obj = {
                  title: coverBlock.innerBlocks.find(
                    x => x.name === "core/heading"
                  ).attributes.content,
                  img: coverBlock.attributes.url,
                  text: text,
                  name:
                    coverBlock.innerBlocks[3] !== undefined &&
                    coverBlock.innerBlocks[3].attributes.content,
                  buttons: button && button,
                }
                testimonial.push(obj)
                return 1
              })
              return (
                <Section className={'overflow-x-hidden'}>
                  <Container className="mw-lg">
                    <CardSliderDuo values={testimonial} />
                  </Container>
                </Section>
              )
            } else {
              return (
                <Section key={`group-${i}`} className="pb-0 bg-light">
                  <Container>
                    <HeadingColumn
                      h2={
                        item.innerBlocks[0].attributes.content || "Join With Us"
                      }
                      text={[
                        item.innerBlocks[1].attributes.content ||
                          "Career at Sinar Mas Land is truly one of a kind experience. It's more than working for.",
                      ]}
                      className="mb-0"
                    />
                  </Container>
                </Section>
              )
            }
          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "acf/location-cards":
            if (item.acf && item.acf.list_card.length > 0) {
              return (
                <Section key={`opportunity-${i}`} className="bg-light">
                  <Container>
                    <div className="row">
                      {item.acf.list_card.map((job, i) => {
                        return (
                          <div
                            className="col-md-4"
                            key={`job-${i}`}
                            onClick={e => {
                              window.dataLayer = window.dataLayer || []
                              window.dataLayer.push({
                                event: "jobCard",
                                event_category: "Button Click",
                                event_action: "Click Job Card",
                                event_category: job.title || "Job Title",
                              })
                            }}
                          >
                            <Card
                              variant="basic"
                              link={job.link}
                              title={job.title || "Job title"}
                              titleClassName="h5"
                              text={
                                job.description ||
                                "Short description of the job letting the users know a bit about job description"
                              }
                              className="card-job w-ic-angle-right"
                            >
                              <span className="card-job-location">
                                {job.location}
                              </span>
                            </Card>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Link
                        to="https://career.sinarmasland.com/"
                        className="btn btn-link fx-underline gtm-allposition"
                      >
                        Lihat Semua Posisi Pekerjaan &nbsp;
                        <i className="far fa-external-link"></i>
                      </Link>
                    </div>
                  </Container>
                </Section>
              )
            }
            return <div key={`empty-block-${i}`}></div>
          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={data.imgCoverMain.childImageSharp.fluid}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={
                    item.attributes.url ||
                    "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
                  }
                  audio={false}
                />
              </div>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}
    </Layout>
  )
}

export default Career
